import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Aligner = makeShortcode("Aligner");
const Flex = makeShortcode("Flex");
const Button = makeShortcode("Button");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "-tokens--mechanism-design",
      "style": {
        "position": "relative"
      }
    }}>{`📈 Tokens & Mechanism Design`}<a parentName="h1" {...{
        "href": "#-tokens--mechanism-design",
        "aria-label": " tokens  mechanism design permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`What is a token? Simple questions like this raise all sorts of thorny problems. The dictionary
informs us that a token is "a thing serving as a visible or tangible representation of a fact."
Older uses point to badges or favours worn to indicate allegiance, or a `}<em parentName="p">{`word`}</em>{` or object
conferring authority or serving to authenticate.`}</p>
    <p>{`In computing, the word means either "the smallest meaningful unit of information in a sequence
of data for a compiler", or "a sequence of bits passed continuously between nodes in a fixed
order and enabling a node to transmit information." So, a token is some object, word, or
sequence which - when communicated - authenticates knowledge or possession of some fact which
grants the holder certain privileges. More succinctly: `}<strong parentName="p">{`tokens transmit information about facts`}</strong>{`.`}</p>
    <p>{`So, what is a fact? `}<a parentName="p" {...{
        "href": "https://youtu.be/-6BsiVyC1kM?t=1207"
      }}>{`Rich Hickey is the best person to ask about this`}</a>{` from a programmer's perspective. Facts are `}<em parentName="p">{`values`}</em>{` which incorporate time because they are "an event or thing known to have happened or existed". The word comes from a `}<em parentName="p">{`past participle`}</em>{` in Latin, `}<strong parentName="p">{`factum`}</strong>{`, meaning "something done". This fits in nicely with our work because crypto-economic tokens on Ethereum are facts about key value mappings in a shared contract, with a visible trail of the values associated with any one key over the entire existence of the contract.`}</p>
    <p>{`In fact, the word "token" shares a root with the Germanic and Dutch words for "teach", which makes sense given that tokens "teach" factual information. This is the actual use of tokens, and mechanisms designed around them, in Web 3: they are tools which can be used to teach by creating factual, `}<a parentName="p" {...{
        "href": "/learn/module-3/lock-it-open/#the-path-forward"
      }}>{`verifiable incentives`}</a>{` for certain behaviours, both individually and collectively.`}</p>
    <p>{`As some dear friends point out, "Attracting people around a common goal isn’t new, but coordinating them with a tradable and functional token is very new." Once we have studied how unbounded search through narrative can reveal universes of prosocial value, we can begin to grasp some of the new affordances coordinating people around blockchains provide, an idea `}<a parentName="p" {...{
        "href": "https://www.daoistry.com/mindsets/blockchain-coordination/"
      }}>{`beautifully described here`}</a>{`.`}</p>
    <p>{`If you'd like to explore some interesting case studies in order to contextualise some of the theory in this module, we encourage you to:`}</p>
    <Aligner center mdxType="Aligner">
      <Flex sx={{
        alignItems: 'center'
      }} mdxType="Flex">
  
  <Button to='/tokens/' secondary inline mdxType="Button">
    Explore this
  </Button>
      </Flex>
    </Aligner>
    <h2 {...{
      "id": "week-5-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 5 Firesides`}<a parentName="h2" {...{
        "href": "#week-5-firesides",
        "aria-label": "week 5 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - October 20, 2022`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/NfKZkIlC2sE?start=245" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - November 4, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/OtP9zVvmG1I?start=329" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Simon de la Rouviere - June 24, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/KQb6ok5SRV4?start=264" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Bea Ramos and Sparrow - Feb 18, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/NUO86413eD4?start=132" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - August 13, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/JVDcfwWkP18?start=361" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      